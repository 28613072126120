<template>
  <div class="addposts">

    <van-nav-bar
        title="我的动态"
        right-text="修改"
        left-arrow
        @click-left="onClickLeft"
        @click-right="modify"
        v-if="isshow"
    />
    
    <van-nav-bar
        title="我的动态"
        right-text="发布"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        v-else
    />

    <div class="addposts-content">
      <input type="text" placeholder="请输入标题" v-model="title"
      :style="{width:'100%',borderBottom:'1px solid #EFEFEF',height:'40px',paddingBottom:'10px'}">
      <textarea
          placeholder="想说点什么..."
          rows="4"
          class="mint-field-core"
          v-model="value"
          :style="{marginTop:'10px'}"
      ></textarea>
    </div>
    <div class="addposts-imgwrap">
      <ul class="addposts-imgwrap-preview">
        <li
            class="addposts-imgwrap-preview-item"
            v-for="(imgItem, index) in succImg"
            :key="imgItem.id"
        >
          <div class="addposts-imgwrap-preview-item-img" @click="getImg(index)">
            <img :src="imgItem.picPath" alt="" />
          </div>
          <div
              class="addposts-imgwrap-preview-item-del"
              @click="delImg(index, imgItem.id)"
          >
            <i class="van-icon van-icon-cross"></i>
          </div>
        </li>
        <!-- <li class="addposts-imgwrap-preview-item">
          <div class="addposts-imgwrap-preview-item-img">
            <img src="" alt="" />
          </div>
          <div class="addposts-imgwrap-preview-item-del">
            <i class="van-icon van-icon-cross"></i>
          </div>
        </li> -->
      </ul>
      <van-uploader :max-count="3" :after-read="afterRead" />
    </div>
  </div>
</template>

<script>
import { uploadImg, delUploadImg,communityDetails,updateContent } from "@api/community.js";
import {addPost} from "../api/community";
import { ImagePreview } from "vant";
import qs from "qs";
export default {
  name: "AddPosts2",
  data() {
    return {
      value: "",
      // 存储上传的图片
      succImg: [],
      title: '',
      id:'',
      isshow:false,
      inputData:'',
      pageType:'',
      postType:"",
      tabType:null,
    };
  },
  computed: {
    imgList() {
      return this.succImg.map((item) => {
        return item.picPath;
      });
    },
  },
  created(){
      this.id = this.$route.query.id
      this.postType = this.$route.params.type
      this.tabType = parseInt(this.$route.query.tabType)
      if(this.id){
         this.isshow = true
         this.getinput()
      }
  },
  methods: {
    //获取信息 数据回显
    async getinput(){
       const res = await communityDetails(this.id,this.postType)
       if(res.code === 0 ){
         this.inputData = res.data
         this.title = this.inputData.title
         this.value = this.inputData.content
         this.succImg = this.inputData.pics
       }
    },
    
    //修改
    async modify(){
      if(this.postType == 1 || this.postType == 2){
        let obj = [];
        this.succImg.map((item) => {
          obj.push(item.id)
        })
        const data = {
          content:this.value,
          id:this.id,
          picIds:obj,
          title:this.title,
          postType:1,
        }
        const res = await updateContent(JSON.stringify(data))
        if(res.code === 0 ){
            this.$toast("修改成功")
            this.value = "";
            this.pageType = sessionStorage.getItem("pageType")
            this.succImg.length = 0;
            if(this.pageType == 1){
              if (this.value === "" && this.succImg.length === 0) {
                this.$router.push({
                    name:'Communityadmin',
                    query:{
                      tabType:this.tabType
                    }
                })
              }
            }else if(this.pageType == 2){
              if (this.value === "" && this.succImg.length === 0) {
                this.$router.replace("/dynamic");
              }
            }else{
              if (this.value === "" && this.succImg.length === 0) {
                    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
                    this.$router.replace(`/Userpersonal?id=${userInfo.userId}`);
                  }
            }

        }else{
          this.$toast("修改失败，请联系管理员！")
        }
      }else {
        let obj = [];
        this.succImg.map((item) => {
          obj.push(item.id)
        })
       const data = {
         content:this.value,
         id:this.id,
         picIds:obj,
         title:this.title,
         postType:2,
       }
        console.log(this.postType)
       if (this.postType == 3){
         data.postType = 3
       }
       const res = await updateContent(JSON.stringify(data))
       if(res.code === 0 ){
          this.$toast("修改成功")
          this.value = "";
          this.pageType = sessionStorage.getItem("pageType")
          this.succImg.length = 0;
          if(this.pageType == 1){
            if (this.value === "" && this.succImg.length === 0) {
               this.$router.push({
                    name:'Communityadmin',
                    query:{
                      tabType:this.tabType
                    }
                })
            }
          }else if(this.pageType == 2){
            if (this.value === "" && this.succImg.length === 0) {
              this.$router.replace("/dynamic");
            }
          }else{
             if (this.value === "" && this.succImg.length === 0) {
                  let userInfo = JSON.parse(localStorage.getItem("userInfo"))
                  this.$router.replace(`/Userpersonal?id=${userInfo.userId}`);
                }
          }

       }else{
         this.$toast("修改失败，请联系管理员！")
       }
      }
      
    },

    getImg(index) {
      ImagePreview({
        images: this.imgList,
        showIndex: true,
        startPosition: index,
      });
    },
    onClickRight() {
      // 内容不能为空
      if (this.value === "") {
        return;
      }
      this.uploadImg();
    },
    
    async afterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      const result = await uploadImg(param);
      if (result.code === 0) {
        this.succImg.push({ ...result.data });
      } else {
        this.$toast(result.msg);
      }
    },
    async uploadImg() {
      // 上传图片  判断当前的图片是单张还是多张
      let picIds = [];
      this.succImg.forEach((item) => {
        picIds.push(item.id);
      });
      const requestParam = {
        title: this.title,
        content: this.value,
        postType: this.$route.params.type,
        picIds,
      };

      const result = await addPost(requestParam);
      if (result.code === 0) {
        this.value = "";
        this.succImg.length = 0;
        if (this.value === "" && this.succImg.length === 0) {
          this.$router.replace("/home/Community2");
        }
      }
    },
    onClickLeft() {
      if (this.value === "" && this.imgList.length === 0 && this.title === "") {
          this.$router.go(-1);
      } else {
        this.$dialog
            .alert({
              message: "您还有内容未发布，是否要退出？",
              showConfirmButton: true,
              showCancelButton: true,
            })
            .then(() => {
              // 点击确定的情况下
              // 存在图图片的情况下
              this.$router.go(-1);

              // if (this.succImg.length !== 0) {
              //   const ids = this.succImg.map((item) => item.id).join(",");
              //   const result = await this.deleteImg(ids);
              //   if (result.code === 0) {
              //     this.value = "";
              //     this.title = "";
              //     next();
              //   } else {
              //     this.$toast(result.msg);
              //   }
              //   // 没有图片则清空文本即可
              // } else {
              //   this.value = "";
              //   next();
              // }
            }).catch(()=>{
              
            });
      }
    },
    delImg(index, id) {
      // 删除指定下标的图片对象
      if (isNaN(index) || index >= this.succImg.length) {
        return false;
      }
      let tmp = [];
      for (let i = 0, len = this.succImg.length; i < len; i++) {
        if (this.succImg[i] !== this.succImg[index]) {
          tmp.push(this.succImg[i]);
        }
      }
      this.succImg = tmp;
      this.deleteImg(id);
    },
    async deleteImg(id) {
      const requestParam = {
        picIds: id,
      };
      const result = await delUploadImg(qs.stringify(requestParam));
      return Promise.resolve(result);
    },
  },
};
</script>

<style lang="scss" scoped>
// 改变vantui的默认样式
::v-deep .van-nav-bar__text {
  font-weight: bold;
  font-size: 17px;
  color: #333;
}
::v-deep .van-nav-bar__arrow {
  color: #333;
}
::v-deep .van-nav-bar__title {
  font-weight: bold;
  font-size: 17px;
  color: #333;
}
.addposts-content {
  position: relative;
  padding: 20px 26px;
  font-size: 15px;
  margin-top: 10px;
  textarea {
    width: 100%;
  }
  &::before {
    position: absolute;
    display: block;
    top: -10px;
    left: 0;
    content: " ";
    width: 375px;
    height: 10px;
    background: #f4f4f4;
  }
}
.addposts-imgwrap {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  &-preview {
    display: flex;
    flex-wrap: wrap;
    &-item {
      position: relative;
      overflow: hidden;
      margin: 0 8px 8px 0;
      &-img {
        display: block;
        width: 80px;
        height: 80px;
        // background-color: #f01414;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }
      &-del {
        position: absolute;
        top: 0;
        right: 0;
        width: 14px;
        height: 14px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 12px;
        i {
          position: absolute;
          top: -2px;
          right: -2px;
          color: #fff;
          font-size: 16px;
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
        }
      }
    }
  }
}
</style>
