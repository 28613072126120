import request from "@/utils/request";
//获取动态列表
export function findArticleList(data) {
  return request({
    url: "/bbs/findArticleList",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//删除评论
export function delComment(data) {
  return request({
    url: "/bbs/delComment",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//取消收藏
export function removeCollect(data) {
  return request({
    url: "/bbs/cancelCollect",
    method: "post",
    data,
  });
}

//添加评论
export function addComment(data) {
  return request({
    url: "/bbs/addComment",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//收藏
export function Collection(data) {
  return request({
    url: "/bbs/contentCollect",
    method: "post",
    data,
  });
}

//搜索
export function findCommunity(data) {
  return request({
    url: "bbs/findContent",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function doEndorse(data) {
  return request({
    url: "/bbs/endorse",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//举报
export function addUserReport(data) {
  return request({
    url: "/common/addUserReport",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}


// 上传图片
// /common/uploadImg

export function uploadImg(data) {
  return request({
    url: "common/uploadImg",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// /bbs/issueArticle
// 发帖
export function sendPost(data) {
  return request({
    url: "/bbs/issueArticle",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//删除图片
export function delUploadImg(data) {
  return request({
    url: "/common/delUploadImg",
    method: "post",
    data,
  });
}
//查找内容
export function findContent(data){
  return request({
    url: '/bbs/findContent',
    method: 'post',
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}
//发布内容
export function addPost(data){
  return request({
    url: 'bbs/addPost',
    method: 'post',
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}
//帖子详情
export function communityDetails(id,type){
  return request({
    url: `bbs/findById/${id}/${type}`,
    method: 'post',
  })
}

//发布内容
export function updateContent(data){
  return request({
    url: 'bbs/updateContent',
    method: 'post',
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

//评论详情
export function CommentDetails(data){
  return request({
    url: 'bbs/findComment',
    method: 'post',
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
}